import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

let websocket = null;

function CheckingBCS() {
  const navigate = useNavigate();
  const [BCSDetected, setBCSDetected] = React.useState(false);
  const [BCSChecking, setBCSChecking] = React.useState(true);
  const stateRef = React.useRef({
    retryCount: 0,
  });

  const onOpen = () => {
    websocket.close();
    navigate("/systemReady");
  };

  const onError = (e) => {
    console.log("WS Error", e);
    websocket.close();
    if (stateRef.current.retryCount > 1) {
      console.log("Not Detected");
      setBCSChecking(false);
      setBCSDetected(false);
    }
  };

  const onClose = () => {
    console.log("WS Close");
    if (!BCSDetected) {
      reconnect();
    }
  };

  const reconnect = () => {
    if (stateRef.current.retryCount <= 1) {
      connect();
    }
  };

  const connect = () => {
    var pre_bcsproto = window.location.protocol === "https:" ? "wss://" : "ws://";
    var pre_bcsport = window.location.protocol === "https:" ? ":32660" : ":32658";
    var pre_site_bcs_fqdn = "bcs.visualware.com";
    var wsHost = pre_bcsproto + pre_site_bcs_fqdn + pre_bcsport;
    var wsHost2 = pre_bcsproto + "127.0.0.1" + pre_bcsport;
    if (websocket?.readyState !== 1) {
      if (stateRef.current.retryCount > 0) {
        wsHost = wsHost2;
      }
      console.log("Trying Connection for " + wsHost);
      stateRef.current.retryCount++;

      websocket = new WebSocket(wsHost);
      websocket.onopen = onOpen;
      websocket.onerror = onError;
      websocket.onclose = onClose;
    }
  };

  const reload = () => {
    if (websocket) {
      websocket.close();
    }
    navigate("/checkingBCS");
  }

  const goback = () => {
    if (websocket) {
      websocket.close();
    }
    navigate("/download");
  }

  useEffect(() => {
    if (!BCSDetected && stateRef.current.retryCount < 1) {
      console.log("Loading again");
      const timer = setTimeout(() => {
        setBCSChecking(true);
        connect();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [BCSDetected]);

  return (
    <>
      <div className="my-[100px]">
        <h1 className="text-[42px] font-semibold text-left text-white">
          Checking, Please Wait! {BCSDetected}
        </h1>
        <p className="text-[15px] text-left text-white mt-2 font-normal">
          Checking for prerequisites....this will take a few seconds. Thank you
          for your patience.
        </p>
        {BCSChecking ? <div className="mt-7 loader"></div> : ""}
        {!BCSDetected && BCSChecking !== true ? (
          <>
            <div
              className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 mt-5"
              role="alert"
            >
              <p className="font-bold text-[20px]">
                Looks like BCS is not Running!
              </p>
              <p className="text-md">
                The Connectivity test ran two tests. Both of these tests failed,
                which indicates that the BCS is either not running correctly or
                is not installed.
              </p>
            </div>
            <button onClick={goback} className="bg-transparent border-2 border-white hover:bg-white hover:text-black px-5 py-3 text-base font-semibold text-center text-white mt-16 duration-500">
              Go Back to BCS Download options
            </button>
            <br />
            <button onClick={reload} className="text-base font-semibold text-center text-white mt-11">
              Check for BCS again
            </button>
            <br />
            {/* Neuer Button für unsichere Verbindung via HTTP */}
            <button
              onClick={() => window.open("http://speedtest80.nfon.net:8080", "_blank")}
              className="bg-transparent border-2 border-white hover:bg-white hover:text-black px-5 py-3 text-base font-semibold text-center text-white mt-5 duration-500"
            >
              Or Use Insecure Connection via HTTP
            </button>

            <div className="my-[100px]">
              <h3 className="text-[17px] font-semibold text-left text-white">
              If the BCS has been installed and the connectivity test is still failing, please check the BCS support website or contact us.
              </h3>
              <div className="pl-5">
                <ul className="list-outside list-disc">
                  <li>
                  <a className="underline" href="https://www.visualware.com/bcs/support/index.html">BCS support website</a>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default CheckingBCS;
