import React from 'react';
import { useNavigate } from 'react-router-dom';

function CheckBCS() {
  const navigate = useNavigate();

  const isSecure = window.location.protocol === "https:";

  function handleSecureClick() {
    if (isSecure) {
      // Sichere Verbindung, Benutzer bleibt auf HTTPS und wird weitergeleitet
      navigate("/checkingBCS");
    } else {
      // Seite mit HTTPS in neuem Tab laden, wenn der Benutzer über HTTP kommt
      window.open("https://speedtest.nfon.net", '_blank');
    }
  }

  function handleInsecureClick() {
    if (isSecure) {
      // Unsichere Verbindung, Seite mit HTTP und Port 8080 in einem neuen Tab öffnen
      window.open("http://speedtest80.nfon.net:8080", '_blank');
    } else {
      // Unsichere Verbindung, Test normal fortsetzen
      navigate("/checkingBCS");
    }
  }

  return (
    <>
      <div className='my-[100px]'>
        <h1 className='text-[42px] font-semibold text-left text-white'>Ready to go?</h1>
        <p className='text-[15px] text-left text-white mt-2 font-normal'>
          You have installed Visualware BCS and are ready to start? Select your connection type below to proceed with the BCS check.
          NOTE:
          Please preferably use the secure connection via HTTPS / WSS.
          If your router is a FritzBOX from the manufacturer AVM (typically in Germany), select the insecure connection via HTTP/WS in case the test via HTTPS/WSS fails.
        </p>

        <div className="mt-5">
          <button
            onClick={handleSecureClick}
            className="bg-transparent border-2 border-white hover:bg-white hover:text-black px-5 py-3 text-base font-semibold text-center text-white mt-5 duration-500"
          >
            Use Secure Connection (HTTPS/WSS)
          </button>
          <br />
          <button
            onClick={handleInsecureClick}
            className="bg-transparent border-2 border-white hover:bg-white hover:text-black px-5 py-3 text-base font-semibold text-center text-white mt-5 duration-500"
          >
            Use Insecure Connection (HTTP/WS on Port 8080)
          </button>
        </div>

        <div className='my-[100px]'>
          <h3 className='text-[17px] font-semibold text-left text-white'>Please Note</h3>
          <div className='pl-5'>
            <ul className='list-outside list-disc'>
              <li>User with a router from AVM / FritzBox maybe need to use the insecure connection via HTTP.</li>  
              <li>The IP address used and the browser used are transmitted. No other personal data is collected and transmitted.</li>
              <li>All test data is transmitted directly to dedicated test systems within the NFON network.</li>
              <li>Presence of some network devices such as firewalls can interfere with test results.</li>
              <li>You will be able to email the assessment results to your IT department or to your NFON reseller.</li>
              <li>The utility will provide you with a Test ID, which is a reference that you will need to provide should you or your reseller need to contact our sales or support teams, so your test results can be located in order to advise you further if necessary.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckBCS;
