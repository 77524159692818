import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
function AssesmentResult() {
  const navigate = useNavigate();
  const resultsString = localStorage.getItem("results");
  if (!resultsString) {
    console.log("ID not detected");
  }
  const results = JSON.parse(resultsString);
  console.log("Session ID detected", results.sessionID);
  const [sessionID] = React.useState(results.sessionID);
  const [selectedServer] = React.useState(results.selectedServer);
  const [speed] = React.useState(results.speed);
  const [firewall] = React.useState(results.firewall);
  const [voip] = React.useState(results.voip);
  const [capacity] = React.useState(results.capacity);
  var [connectionStatus] = "";
  if (
    voip.DJITTER > 50 &&
    voip.JITTER > 50 &&
    voip.RTTMIN > 200 &&
    voip.DPACKETLOSS > 1 &&
    voip.PACKETLOSS > 1 &&
    voip.MOS < 3.5 &&
    voip.DMOS < 3.5
  ) {
    connectionStatus = "red";
  } 
    if (
      voip.DJITTER <= 35 &&
      voip.JITTER <= 35 &&
      voip.RTTMIN <= 200 &&
      voip.DPACKETLOSS <= 0.75 &&
      voip.PACKETLOSS <= 0.75 &&
      voip.MOS >= 3.5 &&
      voip.DMOS >= 3.5
    ) {
      connectionStatus = "amber";
    } 
      if (
        voip.DJITTER <= 20 &&
        voip.JITTER <= 20 &&
        voip.RTTMIN <= 100 &&
        voip.DPACKETLOSS < 0.5 &&
        voip.PACKETLOSS < 0.5 &&
        voip.MOS > 3.8 &&
        voip.DMOS > 3.8
      ) {
        connectionStatus = "green";
      }
    
  

  var maxCalls =  calcSupCalls(calcPps(capacity.DCAPACITY, capacity.UCAPACITY));

function calcPps(down, up) {
    var pps = Math.min(down, up); //get min of down and up speed
    if (pps <= 0) {
        return 0;
    } else {
        pps = pps * 1000; // Convert Kbps to Bits - 9,999Kbps = 9,999,000 bits 
        pps = pps / 172 / 8;
        return pps;
    }
}
function calcSupCalls(finalPps) {
  var ppc = 150 / (172 * 8) * 1000;
  return (finalPps === 0) ? 0 : Math.round(finalPps / ppc);
}


  function handleRestart() {
    navigate("/assesmentRunning");
  }

  function handleEmail() {
    var envbody = document.getElementById("results").innerText;
    var ticketID = sessionStorage.getItem("ticketID");
    var ticketString = "";
    if(ticketID){
      ticketString="Ticket ID : "+ticketID;
    }
    var emailenv =
      "mailto:?subject=Network Assessment Results (Session ID: " + sessionID + ") "+ticketString;
    emailenv += "&body=" + encodeURIComponent(envbody);

    window.location.href = emailenv;
  }
  useEffect(() => {});
  return (
    <>
      <div className="mt-[100px] mb-5">
        <p className="text-[17px] text-center text-white mt-2 font-normal">
          Here are the Test results for your Network Assement
        </p>
        {connectionStatus == "red" ? (
           <div className="bg-red-100 border border-red-500 text-red-700 px-4 py-3 mt-5" role="alert">
           <p className="font-bold text-[20px]">Dang! Based on your network assessment your call quality will be Impossible Communicate.</p>
           <p className="text-md">Please note the session ID in reference above should you require further assistance or guidance regarding the results of this network assessment.</p>
         </div>
        ) : connectionStatus == "amber" ? (
          <div className="bg-yellow-100 border border-yellow-500 text-yellow-700 px-4 py-3 mt-5" role="alert">
          <p className="font-bold text-[20px]">Ouch! Based on your network assessment your call quality will be annoying to very annoying.</p>
          <p className="text-md">Please note the session ID in reference above should you require further assistance or guidance regarding the results of this network assessment.</p>
        </div>
        ) : connectionStatus == "green" ? (
          <div
            className="bg-green-100 border border-green-500 text-green-700 px-4 py-3 mt-5"
            role="alert"
          >
            <p className="font-bold text-[20px]">
              Oho! Based on your network assessment your call quality will be perfect to fair
            </p>
            <p className="text-md">
              Please note the
              session ID in reference above should you require further
              assistance or guidance regarding the results of this network
              assessment.
            </p>
          </div>
        ) : (
          "no conclusive result"
        )}
       
        <div
          id="results"
          className="w-full mt-10 flex gap-5 justify-items-stretch"
        >
          <div className="border border-white grow">
            <div className="border-b text-center border-white p-3">
              <p className="text-white text-[17px] font-semibold">Speed Test</p>
            </div>
            <div className="p-4 grid grid-rows-4 grid-flow-col gap-4">
              <div className="">
                <p className="text-white text-[17px] font-semibold">
                  {(speed.DSPEED / 1000 / 1000).toFixed(2)}Mbps
                </p>
                <p className="text-white text-[17px] font-normal">
                  Download Speed
                </p>
              </div>
              <div className="">
                <p className="text-white text-[17px] font-semibold">
                  {(speed.USPEED / 1000 / 1000).toFixed(2)}Mbps
                </p>
                <p className="text-white text-[17px] font-normal">
                  Upload Speed
                </p>
              </div>
              <div className="">
                <p className="text-white text-[17px] font-semibold">
                  {speed.QOS}%
                </p>
                <p className="text-white text-[17px] font-normal">
                  Service Consistency
                </p>
              </div>
              <div className="">
                <p className="text-white text-[17px] font-semibold">
                  {speed.AVGRTT}ms
                </p>
                <p className="text-white text-[17px] font-normal">
                  Average RTT
                </p>
              </div>
            </div>
          </div>
          <div className="border border-white grow">
            <div className="border-b text-center border-white p-3">
              <p className="text-white text-[17px] font-semibold">
                Firewall Results
              </p>
            </div>
            <div className="p-4 grid grid-rows-4 grid-flow-col gap-4">
              {[...Array(firewall.PORTTOTAL)].map((index) => (
                <div className="flex" key={index}>
                  <p className="text-white text-[17px] font-normal">
                    {firewall.PROTOCOL[index]} Port{" "}
                    {firewall.PORTTOTAL === 1
                      ? firewall.LOWPORT
                      : firewall.PORT[index]}
                  </p>
                  {firewall.STATUS[index] === 0 ? (
                    <p className="text-red-600 text-[17px] font-semibold ml-4">
                      Closed
                    </p>
                  ) : (
                    <p className="text-green-600 text-[17px] font-semibold ml-4">
                      Open
                    </p>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="border border-white grow">
            <div className="border-b text-center border-white p-3">
              <p className="text-white text-[17px] font-semibold">
                VoIP Results
              </p>
            </div>
            <div className="p-4 grid grid-rows-4 grid-flow-col gap-4">
              <div className="">
                <p
                  className={`text-[17px] font-semibold ${
                    voip.JITTER <= 5
                      ? "text-green-600"
                      : voip.JITTER <= 20
                      ? "text-yellow-600"
                      : "text-red-600}"
                  }`}
                >
                  {voip.JITTER}ms
                </p>
                <p className="text-white text-[17px] font-normal">Jitter up</p>
              </div>
              <div className="">
                <p
                  className={`text-[17px] font-semibold ${
                    voip.DJITTER <= 5
                      ? "text-green-600"
                      : voip.DJITTER <= 20
                      ? "text-yellow-600"
                      : "text-red-600}"
                  }`}
                >
                  {voip.DJITTER}ms
                </p>
                <p className="text-white text-[17px] font-normal">
                  Jitter down
                </p>
              </div>
              <div className="">
                <p
                  className={`text-[17px] font-semibold ${
                    voip.PACKETLOSS < 0.1
                      ? "text-green-600"
                      : voip.PACKETLOSS <= 1
                      ? "text-yellow-600"
                      : "text-red-600}"
                  }`}
                >
                  {voip.PACKETLOSS}%
                </p>
                <p className="text-white text-[17px] font-normal">Loss up</p>
              </div>
              <div className="">
                <p
                  className={`text-[17px] font-semibold ${
                    voip.DPACKETLOSS < 0.1
                      ? "text-green-600"
                      : voip.DPACKETLOSS <= 1
                      ? "text-yellow-600"
                      : "text-red-600}"
                  }`}
                >
                  {voip.DPACKETLOSS}%
                </p>
                <p className="text-white text-[17px] font-normal">Loss down</p>
              </div>
              <div className="">
                <p
                  className={`text-[17px] font-semibold ${
                    voip.RTTMIN <= 100
                      ? "text-green-600"
                      : voip.RTTMIN <= 200
                      ? "text-yellow-600"
                      : "text-red-600}"
                  }`}
                >
                  {voip.RTTMIN}ms
                </p>
                <p className="text-white text-[17px] font-normal">
                  Connections latency
                </p>
              </div>
              <div className="">
                {voip.SIPALGFW === "Y" ? (
                  <p className="text-red-600 text-[17px] font-semibold">
                    Detected!
                  </p>
                ) : voip.SIPALGFW === "N" ? (
                  <p className="text-green-600 text-[17px] font-semibold">
                    Not Detected!
                  </p>
                ) : (
                  <p className=" text-[17px] font-semibold">Not Tested!</p>
                )}

                <p className="text-white text-[17px] font-normal">SIP ALG</p>
              </div>
              <div className="">
              <p
                  className={`text-[17px] font-semibold ${
                    voip.MOS > 3.8
                      ? "text-green-600"
                      : voip.MOS < 3.8
                      ? "text-yellow-600"
                      : voip.MOS < 3.5
                      ? "text-red-600}":""
                  }`}
                >
                  {voip.MOS}
                </p>
                <p className="text-white text-[17px] font-normal">MOS up</p>
              </div>
              <div className="">
                <p
                  className={`text-[17px] font-semibold ${
                    voip.DMOS > 3.8
                      ? "text-green-600"
                      : voip.DMOS < 3.8
                      ? "text-yellow-600"
                      : voip.DMOS < 3.5
                      ? "text-red-600}":""
                  }`}
                >
                  {voip.DMOS}
                </p>
                <p className="text-white text-[17px] font-normal">MOS down</p>
              </div>
            </div>
          </div>
          <div className="border border-white grow">
            <div className="border-b text-center border-white p-3">
              <p className="text-white text-[17px] font-semibold">
                Capacity Results
              </p>
            </div>
            <div className="p-4 grid grid-rows-4 grid-flow-col gap-4">
              <div className="">
                {/* Not sure about these fields */}
                <p  className={`text-[17px] font-semibold ${
                    maxCalls > 2
                      ? "text-green-600"
                      : "text-red-600}"
                  }`}>
                  {maxCalls}
                </p>
                <p className="text-white text-[17px] font-normal">Max Calls</p>
              </div>
              <div className="">
                {/* Not sure about these fields */}
                <p className="text-green-600 text-[17px] font-semibold">
                  2 
                </p>
                <p className="text-white text-[17px] font-normal">
                  Target Calls
                </p>
              </div>
              <div className="">
                <p className="text-white text-[17px] font-semibold">
                  {(capacity.DCAPACITY / 1000).toFixed(2)}Mbps
                </p>
                <p className="text-white text-[17px] font-normal">
                  Bandwidth down
                </p>
              </div>
              <div className="">
                <p className="text-white text-[17px] font-semibold">
                  {(capacity.UCAPACITY / 1000).toFixed(2)}Mbps
                </p>
                <p className="text-white text-[17px] font-normal">
                  Bandwidth up
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="border border-white p-10 mt-5 flex justify-between">
          <div className="grow-0">
            <p className="text-[17px] text-left text-white font-normal">
              Test Session ID:
            </p>
            <p className="text-left text-[18px] font-semibold text-white mt-2 ">
              {sessionID} 
            </p>
            <p className="text-[17px] text-left text-white mt-2 font-normal">
              Please note the session ID in reference above should you require
              further assistance or guidance regarding the results of this
              network assessment.
            </p>
          </div>
          <div className="grow text-right">
            <button
              onClick={handleEmail}
              className="bg-N_Sage hover:bg-N_Sage_Hover px-5 py-3 text-base font-semibold text-center text-black duration-500"
            >
              Email Results
            </button>
            <br></br>
            <button
              className="bg-transparent border-2 border-white hover:bg-white hover:text-black px-5 py-3 text-base font-semibold text-center text-white mt-2 duration-500"
              onClick={handleRestart}
            >
              Restart Test
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default AssesmentResult;
