/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import getSystemString from "../../common/utils";
import { useNavigate } from "react-router-dom";
function AssesmentRunning() {
  // eslint-disable-next-line no-unused-vars
  var m_applet_params;
  const navigate = useNavigate();

  const [overallStatus, setOverallStatus] = React.useState(
    "Initiating Client Connection"
  );

  const [sessionID, setSessionID] = React.useState(randomString(5));

  const [systemString, setsystemString] = React.useState(getSystemString());

  const [selectedServer, setSelectedServer] = React.useState("");

  const [testRunning, setTestRunning] = React.useState(false);

  const [speed, setSpeed] = React.useState(false);
  const [speedProgress, setSpeedProgress] = React.useState(0);

  const [firewall, setFirewall] = React.useState(false);
  const [firewallProgress, setFirewallProgress] = React.useState(0);

  const [voip, setVoip] = React.useState(false);
  const [voipProgress, setVoipProgress] = React.useState(0);

  const [capacity, setCapacity] = React.useState(false);
  const [capacityProgress, setCapacityProgress] = React.useState(0);

  var progressStyles = buildStyles({
    rotation: 1 / 2 + 1 / 8,
    strokeLinecap: "butt",
    trailColor: "#191919",
    textColor: "white",
    pathColor: "#00FF91",
  });
  function randomString(strLength) {
    var result = [];
    strLength = strLength || 5;
    var charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    while (strLength--) {
      // (note, fixed typo)
      result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)));
    }
    return result.join("");
  }
  function selectServer() {
    // Select one of the Satellite servers (Random)
    var server = [
      "http://mcs-nbg.cloud-cfg.com:8080/",
      "http://mcs-muc.cloud-cfg.com:8080/",
    ];
    var selection = server[parseInt(Math.random() * server.length)];
    setSelectedServer(selection);
    return selection;
  }

  function testFinished(
    vJITTER,
    vDJITTER,
    vMOS,
    vDMOS,
    vPACKETLOSS,
    vDPACKETLOSS,
    vRTTMIN,
    vSIPALGFW,
    cDCAPACITY,
    cUCAPACITY,
    cDPACKETS,
    cUPACKETS,
    fLOWPORT,
    fPORT,
    fSTATUS,
    fPORTTOTAL,
    fPROTOCOL,
    sDSPEED,
    sUSPEED,
    sQOS,
    sAVGRTT
  ) {
    setOverallStatus("All tests are completed. Redirecting to Results..");
    var result = {
      speed: { DSPEED: sDSPEED, USPEED: sUSPEED, QOS: sQOS, AVGRTT: sAVGRTT },
      firewall: {
        LOWPORT: fLOWPORT,
        PORT: fPORT,
        STATUS: fSTATUS,
        PORTTOTAL: fPORTTOTAL,
        PROTOCOL: fPROTOCOL,
      },
      voip: {
        JITTER: vJITTER,
        DJITTER: vDJITTER,
        MOS: vMOS,
        DMOS: vDMOS,
        PACKETLOSS: vPACKETLOSS,
        DPACKETLOSS: vDPACKETLOSS,
        RTTMIN: vRTTMIN,
        SIPALGFW: vSIPALGFW,
      },
      capacity: {
        DCAPACITY: cDCAPACITY,
        UCAPACITY: cUCAPACITY,
        DPACKETS: cDPACKETS,
        UPACKETS: cUPACKETS,
      },
      sessionID: sessionID,
      selectedServer: selectedServer,
    };
    localStorage.setItem("results", JSON.stringify(result));
    navigate("/assesmentResult");
  }
  function progress(e) {
    console.log("progress", e);
    if (speed) {
      if (firewall) {
        if (voip) {
          if (!capacity) {
            setCapacityProgress(e);
            setOverallStatus(
              "Validating bandwidth availability and quality for outgoing calls"
            );
          }
        } else {
          setVoipProgress(e);
          setOverallStatus(
            "Validating VoIP call quality jitter/loss/MOS (both directions)"
          );
        }
      } else {
        setFirewallProgress(e);
        setOverallStatus("Performing Firewall open ports test");
      }
    } else {
      setSpeedProgress(e);
      setOverallStatus("Running a Speed Test");
    }
  }
  function speedDone() {
    setSpeed(true);
    setSpeedProgress(100);
  }
  function firewallDone() {
    setFirewall(true);
    setFirewallProgress(100);
  }
  function capacityDone() {
    setCapacity(true);
    setCapacityProgress(100);
  }
  function voipDone() {
    setVoip(true);
    setVoipProgress(100);
  }

  window.speedDone = speedDone;
  window.firewallDone = firewallDone;
  window.capacityDone = capacityDone;
  window.voipDone = voipDone;
  window.progress = progress;
  window.testFinished = testFinished;
  function startTest() {
    var testendpoint = selectServer();
    var canvastags =
      '<canvas id="canvs3" style="background-color:#F0F0F0;"></canvas>';
    // eslint-disable-next-line no-unused-vars
    window.m_applet_params =
      '<applet MAYSCRIPT name="myspeed" code="myspeedserver/applet/myspeed.class" codebase="' +
      testendpoint +
      '" archive="/myspeed/myspeed_s_8.jar,/myspeed/plugins_s_8.jar" width="600" height="400">\
     <param name="testspecid" value="20007">\
     <param name="config" value="default">\
     <param name="autostart" value="yes">\
     <param name="js" value="testFinished($VOIP.JITTER$,$VOIP.DJITTER$,$VOIP.MOS$,$VOIP.DMOS$,$VOIP.PACKETLOSS$,$VOIP.DPACKETLOSS$,$VOIP.RTTMIN$,$VOIP.SIPALGFW$,$CAPACITY.DCAPACITY$,$CAPACITY.UCAPACITY$,$CAPACITY.DPACKETS$,$CAPACITY.UPACKETS$,$FIREWALL.LOWPORT$,$FIREWALL.PORT$,$FIREWALL.STATUS$,$FIREWALL.PORTTOTAL$,$FIREWALL.PROTOCOL$,$SPEED.DSPEED$,$SPEED.USPEED$,$SPEED.QOS$,$SPEED.AVGRTT$)">\
     <param name="js-prog" value="progress($PROGRESS$)">\
     <param name="js-speed" value="speedDone()">\
     <param name="js-firewall" value="firewallDone()">\
     <param name="js-capacity" value="capacityDone()">\
     <param name="js-voip" value="voipDone()">\
     <param name="sid" value="*' +
      sessionID +
      "_" +
      systemString +
      '"></applet>';

    document.getElementById("canvas-container").innerHTML = canvastags;
    document.getElementById("canvas-container").style.display = "none";

    var script = document.createElement("script");
    script.src = "/myspeed/boot.lib";
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  useEffect(() => {
    if (!testRunning) {
      startTest();
    }
  }, [testRunning]);
  return (
    <>
      <div className="my-[100px] ">
        <p className="text-[17px] text-center text-white mt-2 font-normal">
          This is a thorough test and will take a few minutes to complete!{" "}
          {systemString}
        </p>
        <div id="canvas-container"></div>
        <div className="grid grid-cols-1 gap-4 place-items-center mx-[8vw] md:grid-cols-4 mt-11">
          <div className="w-[200px] h-50 relative mx-5" key="Speed Test">
            <CircularProgressbar
              value={speedProgress}
              text={`${speedProgress}%`}
              circleRatio={0.75}
              strokeWidth={10}
              styles={progressStyles}
            />
            <p className="text-center absolute inset-x-0 bottom-0 font-bold text-[17px] text-white opacity-60">
              Speed Test
            </p>
          </div>
          <div className="w-[200px] h-50 relative mx-5" key="Firewall Test">
            <CircularProgressbar
              value={firewallProgress}
              text={`${firewallProgress}%`}
              circleRatio={0.75}
              strokeWidth={10}
              styles={progressStyles}
            />
            <p className="text-center absolute inset-x-0 bottom-0 font-bold text-[17px] text-white opacity-60">
              Firewall Test
            </p>
          </div>
          <div className="w-[200px] h-50 relative mx-5" key="VoIP Test">
            <CircularProgressbar
              value={voipProgress}
              text={`${voipProgress}%`}
              circleRatio={0.75}
              strokeWidth={10}
              styles={progressStyles}
            />
            <p className="text-center absolute inset-x-0 bottom-0 font-bold text-[17px] text-white opacity-60">
              VoIP Test
            </p>
          </div>
          <div className="w-[200px] h-50 relative mx-5" key="Capacity Test">
            <CircularProgressbar
              value={capacityProgress}
              text={`${capacityProgress}%`}
              circleRatio={0.75}
              strokeWidth={10}
              styles={progressStyles}
            />
            <p className="text-center absolute inset-x-0 bottom-0 font-bold text-[17px] text-white opacity-60">
              Capacity Test
            </p>
          </div>
        </div>
        <div className="mx-auto text-center mt-11 flex justify-center">
          <div className="loader inline-block"></div>
          <p className="ml-4 inline-block text-center text-[24px] font-semibold text-white ">
            {overallStatus}
          </p>
        </div>

        <div className="border border-white p-10 mt-32">
          <div className="w-full">
            <p className="text-[17px] text-left text-white font-normal">
              Test Session ID:
            </p>
            <p className="text-left text-[18px] font-semibold text-white mt-2 ">
              {sessionID} 
            </p>
            <p className="text-[17px] text-left text-white mt-2 font-normal">
              Please note the session ID in reference above should you require
              further assistance or guidance regarding the results of this
              network assessment.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default AssesmentRunning;
