import React from "react";
import TitleSection from '../../TitleSection'

function StepLayout({ children, heroImage }){
    return (
        <div className=" bg-black ">
        <div className='bg-black h-full w-full '>
          <div className='flex items-start  font-Pangram  mx-auto  h-screen  overflow-hidden'>
          <div className='item w-full text-white overflow-y-auto h-full '>
            <div className='px-[8vw] pt-10 text-left'>
              <TitleSection></TitleSection>
              {children}
            </div>
          </div>
          {heroImage ? <div className='item w-full box-content  h-screen overflow-hidden hidden md:block'>
            <div className='w-full h-full bg-gray-800'>
            {heroImage}
            <img className='object-cover w-[50vw] h-screen' src='bg-image.png' alt='Logo'/>
            </div>
          </div>:""}
          
          </div>
        </div>
      </div>
    )

}
StepLayout.defaultProps = {
  children: "",
  heroImage: true
};

StepLayout.propTypes = {
  children: "",
  heroImage: Boolean
};

export default StepLayout;