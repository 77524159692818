import React from 'react';
import { useNavigate } from 'react-router-dom';

function TitleSection(){
    const navigate = useNavigate();
    function handleClick(){
        navigate('/')
    }
    return (
<div className='flex items-center space-x-5'>
                <img className='w-[150px] cursor-pointer' src='NFON_logo_white.png' alt='Logo' onClick={handleClick}/>
                <h3>Cloudya Apps Network Assessment</h3>
              </div>
    )
}

export default TitleSection