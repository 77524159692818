import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import React from 'react'
import DownloadPrompt from "./pages/DownloadPrompt";
import StepLayout from "./modules/layouts/StepLayout";
import LandingPage from "./pages/LandingPage";
import CheckBCS from "./pages/CheckBCS";
import CheckingBCS from "./pages/CheckingBCS";
import SystemReady from "./pages/SystemReady";
import AssesmentRunning from "./pages/AssesmentRunning";
import AssesmentResult from "./pages/AssesmentResult";
const routes = [
  { path: "/", name: "Home", Component: LandingPage },
  { path: "/download", name: "Download Prompt", Component: DownloadPrompt },
  { path: "/checkBCS", name: "Check BCS", Component: CheckBCS },
  { path: "/checkingBCS", name: "Checking BCS", Component: CheckingBCS },
  { path: "/systemReady", name: "System Ready", Component: SystemReady },
  { path: "/assesmentRunning", name: "Check BCS", Component: AssesmentRunning },
  { path: "/assesmentResult", name: "Check BCS", Component: AssesmentResult },
];

function App() {
  const location = useLocation();
  var heroImage = true;
  console.log("location", location);
  if (
    location.pathname === "/assesmentRunning" ||
    location.pathname === "/assesmentResult"
  ) {
    heroImage = false;
  }
  return (
    <StepLayout heroImage={heroImage}>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300} >
          <Routes>
            {routes.map(({ path, Component }) => (
              <Route key={path} exact path={path} element={<Component />} />
            ))}
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </StepLayout>
  );
}

export default App;
